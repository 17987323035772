import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {logout} from '../authorization/actions/auth'
import {NavLink} from "react-router-dom";
import {getAdmin} from "../../utils/AuthUtill";

class Head extends Component {
    constructor (props) {
        super(props)
        this.state = {
            admin: null,
            iamActions: [],
            isHasAccessAdmins: false,
        }
    }

    componentWillMount() {
        const iamActionsSet = {};
        const admin = getAdmin();
        if(admin && admin.iamRoles) {
            admin.iamRoles.forEach(role => {
                role.actions.forEach(action => {
                    iamActionsSet[action] = true;
                })
            })
        }
        const iamActions = Object.keys(iamActionsSet);
        this.setState({
            admin,
            iamActions,
            isHasAccessAdmins: iamActions.indexOf('webUI:AccessAdmins') !== -1,
        })
    }

    isActive = (path) => {
        return (match, location) => {
            return location.pathname === path;
        }
    }

    render () {
        return (
            <div className='head-panel'>
                <NavLink isActive={this.isActive("/")} to={'/'}  className="head-item" activeClassName={"head-item-active"}>
                    <div>USERS</div>
                </NavLink>
                {(this.state.admin && this.state.admin.adminType === "SUPERADMIN") || this.state.isHasAccessAdmins  ?
                    <NavLink isActive={this.isActive("/admins")} to={'/admins'} className="head-item" activeClassName={"head-item-active"}>
                        <div>ADMINS</div>
                    </NavLink> : <div></div>
                }

                <div className='head-item logout-btn'
                     onClick={() => (this.props.logout())}>
                    LOGOUT
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    logout: bindActionCreators(logout, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Head)
