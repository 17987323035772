import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Modal from './Modal';
import {ErrorDetails} from './ErrorDetails';
import {DeleteAttention} from './DeleteAttention';
import {setDeleteUser, setError, showModal, typeModal} from './actions/general';
import {deleteUser} from '../usersPage/actions/users';

const Modals = ({
  showModal, setError, error, setDeleteUser, userForDelete, deleteUser,
}) => {
  return <div style={{position: 'relative'}}>

    <Modal modalType={typeModal.ERROR} onClose={() => setError(null)}>
      <ErrorDetails
        error={error}
        cancel={() => {
          setError(null);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.USER_DELETE} onClose={() => setDeleteUser(null)}>
      <DeleteAttention
        description={'User ' + (userForDelete ? userForDelete.name : '') + ' ?'}
        cancel={() => {
          setDeleteUser(null);
        }}
        onDelete={() => {
          setDeleteUser(null);
          deleteUser(userForDelete.id);
        }}
      />
    </Modal>
  </div>;
};

const mapStateToProps = (state) => ({
  error: state.appState.error,
  userForDelete: state.appState.userForDelete,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
  setError: bindActionCreators(setError, dispatch),
  setDeleteUser: bindActionCreators(setDeleteUser, dispatch),
  deleteUser: bindActionCreators(deleteUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modals);
