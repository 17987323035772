import React, { Component } from 'react'
import MDSpinner from "react-md-spinner";
import {Admin} from "./Admin"
import {AddAdmin} from "./AddAdmin";

export class AdminsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {isAddMode: false};
    }

    cancelAddMode = () => {
        this.setState({isAddMode: false})
    }

    componentWillMount () {
        this.props.getAdmins(this.props.searchParams)
    }

    renderAddAdmin = () => {
        return this.state.isAddMode ? <AddAdmin
                addAdmin={this.props.addAdmin}
                cancel={this.cancelAddMode}
            /> :
                <div className="add-container-btn noselect" onClick={() => this.setState({isAddMode: true})} >
                    ADD
                </div>
    }

    renderHeaderElements = () => {
        const { searchParams, columnsTable } = this.props

        return columnsTable.map(header => {
            const isOrder = searchParams.orderBy === header.keyOrder
            const isAsc = "ASC" === searchParams.orderIndex

            return <th className="users-stats-table-header-item noselect"
                       key={header.keyName}
            >
                    <div className={"users-stats-table-header-order-item"}
                         onClick={() => this.order(header.keyOrder,  isAsc ? "DESC" : "ASC")}>
                        <div className={"up arrow" + (isOrder && isAsc ? "-active" : "")}></div>
                        <div className="users-stats-table-header-text">
                            {header.keyName}
                        </div>
                        <div className={"down arrow" + (isOrder && !isAsc ? "-active" : "")}></div>
                    </div>
            </th>
        })
    }

    order = (orderBy, orderIndex) => {
        const { searchParams } = this.props

        if(searchParams.orderIndex !== orderIndex){
            this.props.handleChangeSearch([
                {propName: "orderBy", value: orderBy},
                {propName: "orderIndex", value: orderIndex}
            ])
        }
    }

    render () {
        const { isLoadingAdmins, admins, isLoadingIamActions, isLoadingIamRoles } = this.props
        if (isLoadingAdmins || isLoadingIamActions || isLoadingIamRoles) {
            return (
                <div className='users-stats-table-container spinner-container'>
                    <MDSpinner size={120} />
                </div>
            )
        }
        return (
            <div className='users-stats-table-container'>
                {this.renderAddAdmin()}
                <table>
                    <thead>
                    <tr>
                        {this.renderHeaderElements()}
                    </tr>
                    </thead>
                    <tbody>
                    {admins.map((admin) =>
                        <Admin
                            key={admin.id}
                            admin={admin}
                            iamRoles={this.props.iamRoles}
                            deleteAdmin={this.props.deleteAdmin}
                            updateAdmin={this.props.updateAdmin}
                        />
                    )}
                    </tbody>
                </table>
            </div>
        )
    }
}
