import uuid from 'uuid/v4';
import {
  SAVE_ADMINS,
  IS_LOADING_ADMINS,
  CHANGE_SEARCH_PARAMS_ADMINS,
  DELETE_ADMIN,
  CHANGE_ADMIN,
  ADD_ADMIN, SAVE_IAM_ACTIONS, IS_LOADING_IAM_ACTIONS, SAVE_IAM_ROLES, IS_LOADING_IAM_ROLES,
} from '../actions/admins';

const getInintialState = () => {
  return {
    stateId: uuid(),

    admins: [],
    isLoadingAdmins: false,
    timeStampLoadingAdmins: 0,
    iamActions: [],
    isLoadingIamActions: false,
    timeStampLoadingIamActions: 0,
    iamRoles: [],
    isLoadingIamRoles: false,
    timeStampLoadingIamRoles: 0,
    countPageAdmins: 0,
    searchParamsAdmins: {
      filter: '',
      limit: 10,
      page: 1,
      orderBy: 'admin.name',
      orderIndex: 'ASC',
    },

    timeStampAddAdmin: 0,

    columnsTable: [
      {keyName: 'id', keyOrder: 'admin.id'},
      {keyName: 'name', keyOrder: 'admin.name'},
      {keyName: 'nickname', keyOrder: 'admin.nickname'},
      {keyName: 'account type', keyOrder: 'admin.adminType'},
      {keyName: 'iam roles', keyOrder: null},
      /*      {keyName: 'createdAt', keyOrder: 'admin.createdAt'},
      {keyName: 'updatedAt', keyOrder: 'admin.updatedAt'},*/
    ],
  };
};

export default function admins(state = getInintialState(), action) {
  switch (action.type) {
    case SAVE_ADMINS:
      const timeStampAdmins = action.requestTimeEpoch;
      const stateIdRequestedAdmins = action.stateId;
      if (
        timeStampAdmins > state.timeStampLoadingAdmins &&
                stateIdRequestedAdmins === state.stateId
      ) {
        return Object.assign({}, state, {
          admins: action.admins,
          isLoadingAdmins: false,
          timeStampLoadingAdmins: timeStampAdmins,
          countPageAdmins: action.pageTotal,
        });
      }
      return state;

    case IS_LOADING_ADMINS:
      return Object.assign({}, state, {
        isLoadingAdmins: action.isLoading,
      });

    case SAVE_IAM_ACTIONS:
      const timeStampIamActions = action.requestTimeEpoch;
      const stateIdRequestedIamActions = action.stateId;
      if (
        timeStampIamActions > state.timeStampLoadingIamActions &&
          stateIdRequestedIamActions === state.stateId
      ) {
        return Object.assign({}, state, {
          iamActions: action.iamActions,
          isLoadingIamActions: false,
          timeStampLoadingIamActions: timeStampIamActions,
        });
      }
      return state;

    case IS_LOADING_IAM_ACTIONS:
      return Object.assign({}, state, {
        isLoadingIamActions: action.isLoading,
      });

    case SAVE_IAM_ROLES:
      const timeStampIamRoles = action.requestTimeEpoch;
      const stateIdRequestedIamRoles = action.stateId;
      if (
        timeStampIamRoles > state.timeStampLoadingIamRoles &&
          stateIdRequestedIamRoles === state.stateId
      ) {
        return Object.assign({}, state, {
          iamRoles: action.iamRoles,
          isLoadingIamRoles: false,
          timeStampLoadingIamRoles: timeStampIamRoles,
        });
      }
      return state;

    case IS_LOADING_IAM_ROLES:
      return Object.assign({}, state, {
        isLoadingIamRoles: action.isLoading,
      });

    case CHANGE_SEARCH_PARAMS_ADMINS:
      const objectWithNewValues = {};
      action.params.forEach((param) => {
        objectWithNewValues[param.propName] = param.value;
      });

      return Object.assign({}, state, {
        searchParamsAdmins: Object.assign(
            {},
            state.searchParamsAdmins,
            objectWithNewValues,
        ),
      });

    case DELETE_ADMIN:
      const filteredAdmins = state.admins
          .filter((admin) => {
            return admin.id !== action.adminId;
          });

      return Object.assign({}, state, {
        admins: filteredAdmins,
      });

    case ADD_ADMIN:
      const timeStampAddAdmin = action.requestTimeEpoch;
      const stateIdRequestedAddAdmin = action.stateId;
      if (
        timeStampAddAdmin > state.timeStampLoadingAdmins &&
                stateIdRequestedAddAdmin === state.stateId
      ) {
        const copyForAdd = [...state.admins];
        copyForAdd.unshift(action.admin);

        return Object.assign({}, state, {
          admins: copyForAdd,
          isLoadingAdmins: false,
          timeStampAddAdmin: timeStampAddAdmin,
        });
      }
      return state;

    case CHANGE_ADMIN:
      const changedAdmins = state.admins
          .map((admin) => admin.id === action.admin.id ? action.admin : admin);

      return Object.assign({}, state, {
        admins: changedAdmins,
      });

    default: return state;
  }
}
