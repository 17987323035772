import uuid from 'uuid/v4';
import {
  SAVE_USERS_STATS,
  IS_LOADING_USERS_STATS,
  CHANGE_SEARCH_PARAMS_USERS_STATS,
  IS_ACTIVE_USER_STATS_COLUMN_TABLE,
  DELETE_USER,
} from '../actions/users';

const getInintialState = () => {
  return {
    stateId: uuid(),

    usersStats: [],
    isLoadingUsersStats: false,
    timeStampLoadingUsersStats: 0,
    countPageUserStats: 0,
    countUserStats: 0,
    searchParamsUserStats: {
      filter: '',
      limit: 10,
      page: 1,
      orderBy: 'updated_at',
      orderIndex: 'ASC',
    },

    columnsTable: [
      {keyName: 'id', keyOrder: 'id', isActive: true},
      {keyName: 'username', keyOrder: 'name', isActive: true},
      {keyName: 'expiration', keyOrder: 'user_premiumExpirationDate', isActive: true, isBlockOrder: true},
      {keyName: 'media count', keyOrder: 'media_count', isActive: true},
      {keyName: 'updates count', keyOrder: 'profile_updates_count', isActive: true},
      {keyName: 'registration', keyOrder: 'created_at', isActive: true},
      {keyName: 'followers(OLD)', keyOrder: 'followers_count', isActive: true},
      {keyName: 'updatedAt', keyOrder: 'updated_at', isActive: true},
    ],
  };
};

const unpackUserStats = (userStats) => {
  return userStats
      .filter((userStat) => {
        return userStat.users[0];
      })
      .map((userStat) => {
        const newUserStat = {...userStat};
        newUserStat['user'] = userStat.users.sort((a, b) => {
          const createdA = a.createdAt;
          const createdB = b.createdAt;

          if (createdA > createdB) {
            return -1;
          }
          if (createdA < createdB) {
            return 1;
          }
          return 0;
        })[0];
        delete newUserStat.users;
        newUserStat.user['inpreviewUserStatsId'] = newUserStat.id;
        return newUserStat;
      });
};

export default function users(state = getInintialState(), action) {
  switch (action.type) {
    case SAVE_USERS_STATS:
      const timeStampUserStats = action.requestTimeEpoch;
      const stateIdRequestedUserStats = action.stateId;
      if (
        timeStampUserStats > state.timeStampLoadingUsersStats &&
                stateIdRequestedUserStats === state.stateId
      ) {
        return Object.assign({}, state, {
          usersStats: unpackUserStats(action.usersStats),
          isLoadingUsersStats: false,
          timeStampLoadingUsersStats: timeStampUserStats,
          countPageUserStats: action.pageTotal,
          countUserStats: action.usersStats.length,
        });
      }
      return state;

    case IS_LOADING_USERS_STATS:
      return Object.assign({}, state, {
        isLoadingUsersStats: action.isLoading,
      });

    case DELETE_USER:
      return Object.assign({}, state, {
        usersStats: state.usersStats.filter((userStats) => {
          return userStats.id !== action.id;
        }),
      });

    case CHANGE_SEARCH_PARAMS_USERS_STATS:
      const objectWithNewValues = {};
      action.params.forEach((param) => {
        objectWithNewValues[param.propName] = param.value;
      });

      return Object.assign({}, state, {
        searchParamsUserStats: Object.assign(
            {},
            state.searchParamsUserStats,
            objectWithNewValues,
        ),
      });

    case IS_ACTIVE_USER_STATS_COLUMN_TABLE:
      const newUserStatsColumns = [...state.columnsTable];
      newUserStatsColumns.forEach((column) => {
        if (column.keyName === action.columnName) {
          column.isActive = action.isActive;
        }
      });

      return Object.assign({}, state, {
        columnsTable: newUserStatsColumns,
      });

    default: return state;
  }
}
