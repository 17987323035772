import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export const IamRolesSelector = ({iamRoles, selectedIamRoles, changeIamRoles, isDisabled=false, isMulti=true, style}) => {
  return <div style={style}>
    <Select
      onChange={(selectedOption) => {
        if (isMulti) {
          changeIamRoles(!selectedOption ? [] : selectedOption.map((option) => option.value));
        } else {
          changeIamRoles(selectedOption.value);
        }
      }}
      isMulti={isMulti}
      value={selectedIamRoles.map((iamRole) => {
        return {value: iamRole.id, label: iamRole.name};
      })}
      isDisabled={isDisabled}
      components={animatedComponents}
      name="iam roles"
      options={iamRoles.map((iamRole) => {
        return {value: iamRole.id, label: iamRole.name};
      })}
      placeholder={'IAM role'}
      className="basic-multi-select select-label"
      classNamePrefix="select"
    />
  </div>;
};


