import {saveItem, getItem} from './LocaStaorageUtill';

export const saveAuth = (auth) => {
  if (auth.access_token_ol) {
    saveItem(auth.access_token_ol, 'access_token_ol');
  }
  if (auth.admin) {
    saveItem(auth.admin, 'admin');
  }

  if (auth.cookies) {
    auth.cookies.forEach((cookie) => {
      console.log(cookie);
      document.cookie = cookie;
    });
  }
};

export const isAuth = () => {
  const accessToken = getItem('access_token_ol', null);

  return accessToken !== null;
};

export const getAccessToken = () => {
  return getItem('access_token_ol', null);
};

export const getAdmin = () => {
  return getItem('admin', null);
};

let handlerAuthState = null;

export const registerLogout = (logoutFunc) => {
  handlerAuthState = logoutFunc;
};

export const logoutApp = () => {
  localStorage.clear();
  sessionStorage.clear();
  handlerAuthState(false);
};
