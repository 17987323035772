import React, { Component } from 'react'
import {bindActionCreators} from "redux"
import { Pagination } from 'semantic-ui-react'
import {connect} from "react-redux"
import debounce from 'throttle-debounce/debounce'

import {getUsersStats, changeSearchParamsUsersStats,
    hiddenUsersStatsColumnTable, updateUser} from './actions/users'
import { setDeleteUser } from '../general/actions/general'
import {UsersStatsTable} from "./UsersStatsTable";

class UsersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {searchParams: this.props.searchParamsUserStats};
        this.getUsersStats = debounce(200, this.props.getUsersStats);
    }

    handleChangeSearch = (params) => {
        const newSearchParams = {}
        params.forEach((param) => {
            newSearchParams[param.propName] = param.value
        })

        const newState = {
            searchParams: Object.assign(
                {},
                this.state.searchParams,
                newSearchParams
            )
        }

        this.setState(newState);
        this.getUsersStats(newState.searchParams);
        this.props.changeSearchParamsUsersStats(params)
    }

    renderSearchPanel = () => {
        return <input type="text"
                      value={this.state.searchParams.filter}
                      onChange={(event) => (
                          this.handleChangeSearch([{
                              value: event.target.value,
                              propName: "filter"
                          }])
                      )}
                      placeholder="Search by username"
                      className="user-stats-search-input"/>
    }

    render () {
            return (
                <div className="users-page">
                    {this.renderSearchPanel()}
                    <UsersStatsTable
                        isLoadingUsersStats={this.props.isLoadingUsersStats}
                        searchParams={this.props.searchParamsUserStats}
                        getUsersStats={this.props.getUsersStats}
                        usersStats={this.props.usersStats}
                        handleChangeSearch={this.handleChangeSearch}
                        hiddenUsersStatsColumnTable={this.props.hiddenUsersStatsColumnTable}
                        columnsTable={this.props.columnsTable}
                        updateUser={this.props.updateUser}
                        setDeleteUser={this.props.setDeleteUser}
                    />
                    <Pagination totalPages={this.props.countPageUserStats}
                                style={{marginTop: "10px"}}
                                    activePage={this.props.searchParamsUserStats.page}
                                    onPageChange={(event, data) => {
                                        this.handleChangeSearch([{
                                            propName: "page",
                                            value: data.activePage
                                        }])
                                    }}
                    />
                </div>
            )
    }
}

const mapStateToProps = (state) => ({
    searchParamsUserStats: state.users.searchParamsUserStats,
    isLoadingUsersStats: state.users.isLoadingUsersStats,
    usersStats: state.users.usersStats,
    columnsTable: state.users.columnsTable,
    countPageUserStats: state.users.countPageUserStats
})

const mapDispatchToProps = (dispatch) => ({
    getUsersStats: bindActionCreators(getUsersStats, dispatch),
    changeSearchParamsUsersStats: bindActionCreators(changeSearchParamsUsersStats, dispatch),
    hiddenUsersStatsColumnTable: bindActionCreators(hiddenUsersStatsColumnTable, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch),
    setDeleteUser: bindActionCreators(setDeleteUser, dispatch)

})

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)
