import React, { Component } from 'react'

export class AddAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {credentials: {
                username: "",
                password: "",
                nickname: ""
            }};
    }

    handleChangeString = (value, propName) => {
        this.setState({
            credentials: Object.assign(
                {},
                this.state.credentials,
                {
                    [propName]: value
                })
        });
    }

    renderAdd = () => {
        return <div>
            <button type="submit"
                    onClick={() => {
                        this.props.addAdmin({
                                credentials: this.state.credentials
                        })
                        this.props.cancel()
                    }}
                    className='add-btn btn noselect'
                    disabled={
                        this.state.credentials.password.length === 0 ||
                        this.state.credentials.username.length === 0
                    }
            >
                add
            </button>
        </div>
    }

    renderCancel = () => {
        return <div>
            <button
                onClick={() => this.props.cancel()}
                className="cancel-btn btn noselect"
            >
                cancel
            </button>
        </div>
    }

    renderName = (credentials) => {
        return <div className="users-stats-table-item-text">
            <input type='text'
                   value={credentials.username}
                   onChange={(event) =>
                       this.handleChangeString(
                           event.target.value,
                           "username"
                       )
                   }
                   placeholder='name'
            />
        </div>
    }

    renderNickname = (credentials) => {
        return <div className="users-stats-table-item-text">
            <input type='text'
                   value={credentials.nickname}
                   onChange={(event) =>
                       this.handleChangeString(
                           event.target.value,
                           "nickname"
                       )
                   }
                   placeholder='nickname'
            />
        </div>
    }

    renderPassword = (credentials) => {
        return <div className="users-stats-table-item-text">
            <input type='password'
                   value={credentials.password}
                   onChange={(event) =>
                       this.handleChangeString(
                           event.target.value,
                           "password"
                       )
                   }
                   placeholder='password'
            />
        </div>
    }

    render () {
        const { credentials } = this.state
        return (
            <div className={"add-container"}>
                {this.renderName(credentials)}
                {this.renderNickname(credentials)}
                {this.renderPassword(credentials)}
                {this.renderCancel()}
                {this.renderAdd()}
            </div>
        )
    }
}
