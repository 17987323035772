import {getAccessToken} from '../../../utils/AuthUtill';
import {configs} from '../../../resources/configs';
import {LOGOUT} from '../../authorization/actions/auth';
import momentZ from 'moment-timezone';

export const SAVE_USERS_STATS = 'SAVE_USERS_STATS';
export const CHANGE_SEARCH_PARAMS_USERS_STATS = 'CHANGE_SEARCH_PARAMS_USERS_STATS';
export const IS_LOADING_USERS_STATS = 'IS_LOADING_USERS_STATS';
export const IS_ACTIVE_USER_STATS_COLUMN_TABLE = 'IS_ACTIVE_USER_STATS_COLUMN_TABLE';
export const DELETE_USER = 'DELETE_USER';

const xhr = new XMLHttpRequest();

export const getUsersStats = (params) => (dispatch, getState) => {
  dispatch({
    type: IS_LOADING_USERS_STATS,
    isLoading: true,
  });

  xhr.open('POST', configs.apiUrl + '/userStats/search', true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  const stateId = getState().users.stateId;
  const timeStampRequestUserStats = momentZ().format('x');

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        dispatch({
          type: SAVE_USERS_STATS,
          stateId,
          requestTimeEpoch: timeStampRequestUserStats,
          usersStats: response.results ? response.results : [],
          pageTotal: response.pageTotal ? response.pageTotal : 0,
        });
      } else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        }
      }
    }
  };

  const payload = {
    paginationOptions: {
      limit: params.limit ? params.limit : 30,
      page: params.page ? params.page : 0,
      order: {
        orderBy: params.orderBy ? params.orderBy : 'userStats.sessions',
        orderIndex: params.orderIndex ? params.orderIndex : 'ASC',
      },
    },
    filter: !params.filter ? '' : params.filter,
  };

  xhr.send(JSON.stringify(payload));
};

export const updateUser = (params) => (dispatch) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PATCH', configs.apiUrl + '/user', true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {} else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        }
      }
    }
  };

  const payload = {...params.user};
  xhr.send(JSON.stringify(payload));
};

export const deleteUser = (id) => (dispatch) => {
  dispatch({
    type: DELETE_USER,
    id,
  });

  const xhr = new XMLHttpRequest();
  xhr.open('DELETE', configs.apiUrl + '/userStats/' + id, true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {} else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        }
      }
    }
  };

  xhr.send();
};

export const changeSearchParamsUsersStats = (params) => (dispatch) => {
  dispatch({
    type: CHANGE_SEARCH_PARAMS_USERS_STATS,
    params,
  });
};

export const hiddenUsersStatsColumnTable = (params) => (dispatch) => {
  dispatch({
    type: IS_ACTIVE_USER_STATS_COLUMN_TABLE,
    columnName: params.columnName,
    isActive: params.isHidden,
  });
};
