import React from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {BrowserRouter, Route} from 'react-router-dom';
import thunk from 'redux-thunk';
import reducer from './reducers';
import UsersPage from './components/usersPage';
import {withAuthenticator} from './components/authorization';
import Head from './components/general/Head';
import AdminsPage from './components/adminsPage';
import Modals from './components/general/Modals';
import './resources/sass/index.scss'
import 'semantic-ui-css/semantic.min.css'
import "react-datepicker/dist/react-datepicker.css"

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(thunk)),
);

const WithProvider = () => (
  <Provider store={store}>
    <BrowserRouter>
      <div className="app-container">
        <Head/>
        <Modals/>
        <Route exact path='/' component={UsersPage} />
        <Route exact path='/admins' component={AdminsPage} />
      </div>
    </BrowserRouter>
  </Provider>
);

export default withAuthenticator(WithProvider);
