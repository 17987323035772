export const SHOW_MODAL = 'SHOW_MODAL';
export const SET_ERROR = 'SET_ERROR';
export const SET_DELETE_USER = 'SET_DELETE_USER';

export const typeModal = {
  USER_DELETE: 'USER_DELETE',
  ERROR: 'ERROR',
};

export const MetaType = {};

export const ComponentType = {
};

export const showModal = (modalType, isShow, meta) => (dispatch) => {
  dispatch({
    type: SHOW_MODAL,
    modalType,
    isShow,
    meta,
  });
};

export const setError = (error) => (dispatch) => {
  dispatch({
    type: SET_ERROR,
    error,
  });
};

export const setDeleteUser = (user) => (dispatch) => {
  dispatch({
    type: SET_DELETE_USER,
    user,
  });
};
