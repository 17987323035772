import {getAccessToken} from '../../../utils/AuthUtill';
import {configs} from '../../../resources/configs';
import {LOGOUT} from '../../authorization/actions/auth';
import momentZ from 'moment-timezone';

export const SAVE_ADMINS = 'SAVE_ADMINS';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const CHANGE_ADMIN = 'CHANGE_ADMIN';
export const ADD_ADMIN = 'ADD_ADMIN';
export const SAVE_IAM_ACTIONS = 'SAVE_IAM_ACTIONS';
export const IS_LOADING_IAM_ACTIONS = 'IS_LOADING_IAM_ACTIONS';
export const SAVE_IAM_ROLES = 'SAVE_IAM_ROLES';
export const IS_LOADING_IAM_ROLES = 'IS_LOADING_IAM_ROLES';

export const CHANGE_SEARCH_PARAMS_ADMINS = 'CHANGE_SEARCH_PARAMS_ADMINS';
export const IS_LOADING_ADMINS = 'IS_LOADING_ADMINS';

export const getAdmins = (params) => (dispatch, getState) => {
  dispatch({
    type: IS_LOADING_ADMINS,
    isLoading: true,
  });

  const xhr = new XMLHttpRequest();
  xhr.open('POST', configs.apiUrl + '/admin/search', true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  const stateId = getState().admins.stateId;
  const requestTimeEpoch = momentZ().format('x');

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);

        dispatch({
          type: SAVE_ADMINS,
          stateId,
          requestTimeEpoch,
          admins: response.results ? response.results : [],
          pageTotal: response.pageTotal ? response.pageTotal : 0,
        });
      } else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        }
      }
    }
  };

  const payload = {
    paginationOptions: {
      limit: params.limit ? params.limit : 30,
      page: params.page ? params.page : 0,
      order: {
        orderBy: params.orderBy ? params.orderBy : 'admin.name',
        orderIndex: params.orderIndex ? params.orderIndex : 'ASC',
      },
    },
    filter: !params.filter ? '' : params.filter,
  };

  xhr.send(JSON.stringify(payload));
};

export const deleteAdmin = (params) => (dispatch) => {
  const xhr = new XMLHttpRequest();
  xhr.open('DELETE', configs.apiUrl + '/admin', true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());


  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        console.log(response);
      } else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        }
      }
    }
  };

  const payload = {
    adminId: params.adminId,
  };

  xhr.send(JSON.stringify(payload));

  dispatch({
    type: DELETE_ADMIN,
    adminId: params.adminId,
  });
};

export const updateAdmin = (params) => (dispatch) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PATCH', configs.apiUrl + '/admin', true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {} else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        }
      }
    }
  };

  const payload = {...params.admin};

  xhr.send(JSON.stringify(payload));

  dispatch({
    type: CHANGE_ADMIN,
    admin: params.admin,
  });
};

export const addAdmin = (params) => (dispatch, getState) => {
  dispatch({
    type: IS_LOADING_ADMINS,
    isLoading: true,
  });

  const xhr = new XMLHttpRequest();
  xhr.open('POST', configs.apiUrl + '/admin/basic', true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  const stateId = getState().admins.stateId;
  const requestTimeEpoch = momentZ().format('x');

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);

        dispatch({
          type: ADD_ADMIN,
          stateId,
          requestTimeEpoch,
          admin: response,
        });
      } else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        } else {
          dispatch({
            type: IS_LOADING_ADMINS,
            isLoading: false,
          });

          alert(xhr.responseText);
        }
      }
    }
  };

  xhr.send(JSON.stringify(params.credentials));
};

export const changeSearchParamsAdmins = (params) => (dispatch) => {
  dispatch({
    type: CHANGE_SEARCH_PARAMS_ADMINS,
    params,
  });
};

export const getIamActions = () => (dispatch, getState) => {
  dispatch({
    type: IS_LOADING_IAM_ACTIONS,
    isLoading: true,
  });

  const xhr = new XMLHttpRequest();
  xhr.open('GET', configs.apiUrl + '/iam-actions', true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  const stateId = getState().admins.stateId;
  const requestTimeEpoch = momentZ().format('x');

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        dispatch({
          type: SAVE_IAM_ACTIONS,
          stateId,
          requestTimeEpoch,
          iamActions: response ? response : [],
        });
      } else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        }
      }
    }
  };

  xhr.send();
};

export const getIamRoles = () => (dispatch, getState) => {
  dispatch({
    type: IS_LOADING_IAM_ROLES,
    isLoading: true,
  });

  const xhr = new XMLHttpRequest();
  xhr.open('GET', configs.apiUrl + '/iam-roles', true);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  const stateId = getState().admins.stateId;
  const requestTimeEpoch = momentZ().format('x');

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);

        dispatch({
          type: SAVE_IAM_ROLES,
          stateId,
          requestTimeEpoch,
          iamRoles: response ? response : [],
        });
      } else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        }
      }
    }
  };

  xhr.send();
};
