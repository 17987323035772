import React, { Component } from 'react'
import {IamRolesSelector} from "../general/IamRolesSelector";

export class Admin extends Component {

    constructor(props) {
        super(props);
        this.state = {admin: this.props.admin};
    }

    handleChangeString = (value, propName) => {
        this.setState({
            admin: Object.assign(
                {},
                this.state.admin,
                {
                    [propName]: value
                })
        });
    }

    renderDeleteSaveButtons = () => {
        return <td className="save-delete-td noselect">
            <button className="save-btn btn"
                 onClick={() => this.props.updateAdmin({admin: this.state.admin})}
            >
                save
            </button>

            <button className="delete-btn btn noselect"
                 onClick={() => this.props.deleteAdmin({adminId: this.props.admin.id})}
            >
                delete
            </button>
        </td>
    }

    renderName = (admin) => {
        return <td className="users-stats-table-item-text">
                <input type='text'
                       value={admin.name}
                       onChange={(event) =>
                           this.handleChangeString(
                               event.target.value,
                               "name"
                           )
                       }
                       placeholder='name'
        />
        </td>
    }

    renderNickname = (admin) => {
        return <td className="users-stats-table-item-text">
            <input type='text'
                   value={admin.nickname ? admin.nickname : ""}
                   onChange={(event) =>
                       this.handleChangeString(
                           event.target.value,
                           "nickname"
                       )
                   }
                   placeholder='nickname'
            />
        </td>

    }

    renderAdminType = (admin) => {
        return <td className="users-stats-table-item-text">
             <select value={admin.adminType}
                    onChange={(event) =>
                        this.handleChangeString(event.target.value, 'adminType')
                    }
                >
                <option value="USER">USER</option>
                <option value="FLTR_REQUESTS_MANAGER">FLTR_REQUESTS_MANAGER</option>
                <option value="ASO_KEYWORDS_AND_FLTR_MANAGER">ASO_KEYWORDS_AND_FLTR_MANAGER</option>
                <option value="UA_MANAGER">UA_MANAGER</option>
                <option value="ASO_MANAGER">ASO_MANAGER</option>
                <option value="ADMIN">ADMIN</option>
                <option value="INPREVIEW_PROMO_FLTR_REQUESTS">INPREVIEW_PROMO_FLTR_REQUESTS</option>
                <option value="RANKS_RATINGS_MANAGER">RANKS_RATINGS_MANAGER</option>
                <option value="SUPERADMIN">SUPERADMIN</option>
             </select>
        </td>
    }
    renderIamRoles = (admin) => {
        return <td className="users-stats-table-item-text">

            <IamRolesSelector
                iamRoles={this.props.iamRoles}
                selectedIamRoles={admin.iamRolesIds.map((iamRoleId) => {
                    return this.props.iamRoles.find(iamRole => iamRole.id === iamRoleId);
                })}
                changeIamRoles={(iamRolesIds) => {
                    this.handleChangeString(iamRolesIds, 'iamRolesIds')
                }}
            />
        </td>

    }

    render () {
        const { admin } = this.state
        return (
            <tr>
                <td className="users-stats-table-item-text">
                    { admin.id }
                </td>
                {this.renderName(admin)}
                {this.renderNickname(admin)}
                {this.renderAdminType(admin)}
                {this.renderIamRoles(admin)}
{/*                <td className="users-stats-table-item-text">
                    { momentZ(admin.createdAt).format("YYYY-MM-DD HH:mm") }
                </td>
                <td className="users-stats-table-item-text">
                    { momentZ(admin.updatedAt).format("YYYY-MM-DD HH:mm") }
                </td>*/}
                {this.renderDeleteSaveButtons()}
            </tr>
        )
    }
}
