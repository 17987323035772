export const saveItem = (item, name) => {
  if (JSON.parse(localStorage.getItem(name)) !== item) {
    localStorage.setItem(name, JSON.stringify(item));
  }
};

export const getItem = (name, def) => {
  const item = localStorage.getItem(name);
  if (item !== null) {
    return JSON.parse(item);
  } else return def;
};
