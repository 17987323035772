import React, { Component } from 'react'
import momentZ from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { ReactComponent as Delete} from '../../resources/imgs/delete-icon.svg';

export class UserStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: this.props.userStats.user,
            isChanged: false
        };
    }

    changeStatusIsChanged = (isChanged) => {
        let newState = {...this.state}
        newState.isChanged = isChanged

        this.setState(newState)
    }

    handleChangeString = (value, propName) => {
        this.setState({
            user: Object.assign(
                {},
                this.state.user,
                {
                    [propName]: value
                }),
            isChanged: true
        });
    }

    handleChangeDate = (date, propName) => {
        this.setState({
            user: Object.assign({}, this.state.user, {
                    [propName]: new momentZ(date).format()
            }),
            isChanged: true
        });
    }


    renderPremiumExpirationDate = (user, columnsTable) => {

        return <td className="users-stats-table-item-text">
            {!columnsTable[2].isActive ? "" :
                <DatePicker
                    selected={new Date(momentZ(this.state.user.premiumExpirationDate).format())}
                    onChange={(date) => this.handleChangeDate(date, 'premiumExpirationDate')}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="yyyy-MM-dd h:mm aa"
                    timeCaption="time"
                />
            }
        </td>
    }

    renderSaveButton = () => {
        return this.state.isChanged ?
        <td className="save-delete-td noselect">
                <button className="save-btn btn"
                    onClick={() => {
                        this.props.updateUser({user: this.state.user})
                        this.changeStatusIsChanged(false)
                    }}
                >
                    save
                </button>
        </td> : ""
    }

    openInstagramUser = (instagramName) => {
        const win = window.open("https://www.instagram.com/" + instagramName + "/", '_blank');
        win.focus();
    }

    render () {
        const { userStats, columnsTable } = this.props
        const { user } = this.state

        return (
            <tr>
                <td className="users-stats-table-item-text">
                    {columnsTable[0].isActive ? userStats.id : ""}
                </td>
                <td className="users-stats-table-item-text hover" onClick={()=>{
                    if(columnsTable[1].isActive){
                        this.openInstagramUser(userStats.name);
                    }
                }}>
                    {columnsTable[1].isActive ? userStats.name : ""}
                </td>
                {this.renderPremiumExpirationDate(user, columnsTable)}
                <td className="users-stats-table-item-text">
                    {columnsTable[3].isActive ? userStats.mediaCount : ""}
                </td>
                <td className="users-stats-table-item-text">
                    {columnsTable[4].isActive ? userStats.profileUpdatesCount : ""}
                </td>
                <td className="users-stats-table-item-text">
                {columnsTable[5].isActive ? momentZ(userStats.createdAt).format("YYYY-MM-DD HH:mm") : ""}
                </td>
                <td className="users-stats-table-item-text">
                    {columnsTable[6].isActive ? userStats.followersCount : ""}
                </td>
                <td className="users-stats-table-item-text">
                    {columnsTable[7].isActive ? momentZ(userStats.updatedAt).format("YYYY-MM-DD HH:mm") : ""}
                </td>
                <td className="users-stats-table-item-text hover" onClick={
                    () => {
                        this.props.setDeleteUser(this.props.userStats)
                    }
                }>
                    <Delete/>
                </td>
                {this.renderSaveButton(this.props.userStats)}
            </tr>
        )
    }
}
