import {SET_DELETE_USER, SET_ERROR, SHOW_MODAL, typeModal} from '../components/general/actions/general';

const getInintialState = () => ({
  isLogged: false,
  admin: undefined,
  modals: (initModalsState()),
  error: null,
  userForDelete: null,
});

export default function appState(state = getInintialState(), action) {
  switch (action.type) {
    case SHOW_MODAL:
      return Object.assign({}, state, {
        modals: Object.assign({}, state.modals, {
          [action.modalType]: {isShow: action.isShow, meta: action.meta},
        }),
      });

    case SET_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        modals: Object.assign({}, state.modals, {
          [typeModal.ERROR]: {isShow: Boolean(action.error), meta: {}},
        }),
      });

    case SET_DELETE_USER:

      return Object.assign({}, state, {
        userForDelete: action.user,
        modals: Object.assign({}, state.modals, {
          [typeModal.USER_DELETE]: {isShow: Boolean(action.user), meta: {}},
        }),
      });
    default:
      return state;
  }
}

const initModalsState = () => {
  const modalState = {};
  Object.keys(typeModal).forEach((keyModal) => {
    modalState[keyModal] = {isShow: false, meta: {}};
  });

  return modalState;
};
