import {combineReducers} from 'redux';
import appState from './appState';
import auth from '../components/authorization/reducers/auth';
import users from '../components/usersPage/reducers/users';
import admins from '../components/adminsPage/reducers/admins';

const appReducer = combineReducers({
  appState,
  auth,
  users,
  admins,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
