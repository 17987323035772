import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Pagination} from "semantic-ui-react"
import {bindActionCreators} from "redux"
import debounce from 'throttle-debounce/debounce'

import {
    changeSearchParamsAdmins, addAdmin,
    getAdmins, deleteAdmin, updateAdmin, getIamActions, getIamRoles
} from './actions/admins'
import {AdminsTable} from "./AdminsTable";

class AdminsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {searchParams: this.props.searchParamsAdmins};
        this.getAdmins= debounce(200, this.props.getAdmins);
        this.props.getIamActions();
        this.props.getIamRoles();
    }

    handleChangeSearch = (params) => {
        const newSearchParams = {}
        params.forEach((param) => {
            newSearchParams[param.propName] = param.value
        })

        const newState = {
            searchParams: Object.assign(
                {},
                this.state.searchParams,
                newSearchParams
            )
        }

        this.setState(newState);
        this.getAdmins(newState.searchParams);
        this.props.changeSearchParamsAdmins(params)
    }

    renderSearchPanel = () => {
        return <input type="text"
                      value={this.state.searchParams.filter}
                      onChange={(event) => (
                          this.handleChangeSearch([{
                              value: event.target.value,
                              propName: "filter"
                          }])
                      )}
                      placeholder="Search by name"
                      className="user-stats-search-input"/>
    }

    render () {
        return (
            <div className="users-page">
                {this.renderSearchPanel()}
                <AdminsTable
                    isLoadingAdmins={this.props.isLoadingAdmins}
                    isLoadingIamActions={this.props.isLoadingIamActions}
                    isLoadingIamRoles={this.props.isLoadingIamRoles}
                    iamRoles={this.props.iamRoles}
                    iamActions={this.props.iamActions}
                    searchParams={this.props.searchParamsAdmins}
                    getAdmins={this.props.getAdmins}
                    admins={this.props.admins}
                    handleChangeSearch={this.handleChangeSearch}
                    columnsTable={this.props.columnsTable}
                    deleteAdmin={this.props.deleteAdmin}
                    updateAdmin={this.props.updateAdmin}
                    addAdmin={this.props.addAdmin}
                />
                <Pagination totalPages={this.props.countPageAdmins}
                            style={{marginTop: "10px"}}
                            activePage={this.props.searchParamsAdmins.page}
                            onPageChange={(event, data) => {
                                this.handleChangeSearch([{
                                    propName: "page",
                                    value: data.activePage
                                }])
                            }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    searchParamsAdmins: state.admins.searchParamsAdmins,
    isLoadingAdmins: state.admins.isLoadingAdmins,
    admins: state.admins.admins,
    isLoadingIamActions: state.admins.isLoadingIamActions,
    iamActions: state.admins.iamActions,
    isLoadingIamRoles: state.admins.isLoadingIamRoles,
    iamRoles: state.admins.iamRoles,
    columnsTable: state.admins.columnsTable,
    countPageAdmins: state.admins.countPageAdmins
})

const mapDispatchToProps = (dispatch) => ({
    getAdmins: bindActionCreators(getAdmins, dispatch),
    getIamActions: bindActionCreators(getIamActions, dispatch),
    getIamRoles: bindActionCreators(getIamRoles, dispatch),
    changeSearchParamsAdmins: bindActionCreators(changeSearchParamsAdmins, dispatch),
    deleteAdmin: bindActionCreators(deleteAdmin, dispatch),
    updateAdmin: bindActionCreators(updateAdmin, dispatch),
    addAdmin: bindActionCreators(addAdmin, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminsPage)