import React, { Component } from 'react'
import MDSpinner from "react-md-spinner";
import {UserStats} from "./UserStats"
export class UsersStatsTable extends Component {
    componentWillMount () {
        this.props.getUsersStats(this.props.searchParams)
    }

    renderHeaderElements = () => {
        const { searchParams, columnsTable } = this.props

        return columnsTable.map(header => {
          const isOrder = searchParams.orderBy === header.keyOrder
          const isAsc = "ASC" === searchParams.orderIndex

          return <th className="users-stats-table-header-item noselect"
                     key={header.keyName}
          >
              <div className="hidden-table-column-item"
                   onClick={() => this.hidden(header.keyName, !header.isActive)}
              >
                  {header.isActive ? "-" : "+"}
              </div>
              {header.isActive ?
                  <div className={"users-stats-table-header-order-item"}
                       onClick={() => {
                           if(!header.isBlockOrder){
                               this.order(header.keyOrder,  isAsc ? "DESC" : "ASC")}
                           }
                       }>
                      <div className={"up arrow" + (isOrder && isAsc ? "-active" : "")}></div>
                      <div className="users-stats-table-header-text">
                          {header.keyName}
                      </div>
                      <div className={"down arrow" + (isOrder && !isAsc ? "-active" : "")}></div>
                  </div> :
                  <div> </div>
              }
                </th>
        })
    }

    hidden = (columnName, isHidden) => {
        this.props.hiddenUsersStatsColumnTable({columnName, isHidden})
    }

    order = (orderBy, orderIndex) => {
        const { searchParams } = this.props

        if(searchParams.orderIndex !== orderIndex){
            this.props.handleChangeSearch([
                {propName: "orderBy", value: orderBy},
                {propName: "orderIndex", value: orderIndex}
            ])
        }
    }

    render () {
        const { isLoadingUsersStats, usersStats } = this.props
        if (isLoadingUsersStats) {
            return (
                <div className='users-stats-table-container spinner-container'>
                    <MDSpinner size={120} />
                </div>
            )
        }
        return (
            <div className='users-stats-table-container'>
                <table>
                    <thead>
                        <tr>
                            {this.renderHeaderElements()}
                        </tr>
                    </thead>
                    <tbody>
                        {usersStats.map((userStats) =>
                            <UserStats
                                key={userStats.id}
                                userStats={userStats}
                                columnsTable={this.props.columnsTable}
                                updateUser={this.props.updateUser}
                                setDeleteUser={this.props.setDeleteUser}
                            />
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}
